import { InferType, object, string } from "yup";
import { ExampleFormData, PartData } from "../../../types/form";
import {
  defaultContextFields,
  generateDateBeforeTodayValidationRule,
  hospital,
  isTimeString,
  isoDateTimeString,
  nonEmptyRequiredString,
  nonEmptyString,
} from "../../shared";

const reportFurnishedBy = string()
  .oneOf(["internal-mail-system", "electronic-communication", "in-person"])
  .default(null)
  .required("Please select the option which applies");

export const m2Part1DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
    approvedClinicianRequired: true,
  }),

  hospital: hospital,

  nearestRelativeName: nonEmptyString.required().nonNullable(
    // @ts-ignore
    "Please enter name of nearest relative who gave notice of an intention to discharge ",
  ),

  nearestRelativeIntentionToDischarge: generateDateBeforeTodayValidationRule(
    "date-time",
    "User cannot enter a date in the future.",
  ).required(
    "Enter date and time nearest relative gave notice of an intention to discharge",
  ),

  reasons: nonEmptyRequiredString.required("Please enter your reasons"),

  reportFurnishedBy: reportFurnishedBy,

  timeConsigning: isTimeString.when("reportFurnishedBy", {
    is: (value: string) => value === "internal-mail-system",
    then: () => isTimeString.required("Please enter a time"),
    otherwise: () => string().optional(),
  }),
});

export const m2Part2DataSchema = object({
  ...defaultContextFields({
    userAddressOptional: true,
  }),

  reportFurnishedBy: reportFurnishedBy,
  reportReceivedDateTime: isoDateTimeString.when("reportFurnishedBy", {
    is: (val: string | boolean) => val === "in-person",
    then: () =>
      // @ts-ignore
      generateDateBeforeTodayValidationRule(
        "date-time",
        "User cannot enter a date and/or in the future.",
      ).required("Please enter date and time this report was received on"),
    otherwise: () => string().optional(),
  }),
});

export type M2Part1DataSchema = InferType<typeof m2Part1DataSchema>;

export type M2Part2DataSchema = InferType<typeof m2Part2DataSchema>;

export type M2Data = [
  PartData<M2Part1DataSchema>,
  PartData<M2Part2DataSchema>?,
];

export const exampleM2Data: ExampleFormData<M2Data> = [
  {
    data: {
      hospital: {
        isConfirmed: true,
        name: "Hospital",
        address: "Hospital Address",
        postalCode: "ABC 123",
      },
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      nearestRelativeName: "Name",
      nearestRelativeIntentionToDischarge: "2022-01-10T12:00:00.000Z",
      reasons: "Reasons",

      reportFurnishedBy: "electronic-communication",
      timeConsigning: null,
    },
  },
  {
    data: {
      user: {
        name: "Clinician",
        email: "david@clinician.com",
        address: "Clinician Address",
        postalCode: "ABC 123",
        isConfirmed: true,
        isSection12Approved: true,
        isGuest: true,
      },
      patient: {
        name: "Patient",
        address: "Patient Address",
        postalCode: "ABC 123",
        dateOfBirth: "1990-01-01",
        nhsNumber: "1234567890",
      },

      reportFurnishedBy: "in-person",
      reportReceivedDateTime: "2022-01-10T12:00:00.000Z",
    },
  },
];
