import Pusher, { Options } from "pusher-js";
import { PusherContextValues, PusherProviderProps } from "./types";
import React, { useEffect, useRef, useState } from "react";

import { dequal } from "dequal";

// context setup
const PusherContext = React.createContext<PusherContextValues>({});
export const __PusherContext = PusherContext;

export const CorePusherProvider: React.FC<
  PusherProviderProps & { children: React.ReactNode }
> = ({
  clientKey,
  triggerEndpoint,
  channelPrefix,
  defer = false,
  children,
  _PusherRuntime,
  ...props
}) => {
  // errors when required props are not passed.
  useEffect(() => {
    if (!clientKey) console.error("A client key is required for pusher");
  }, [clientKey]);

  const config: Options = { ...props };

  // track config for comparison
  const previousConfig = useRef<Options | undefined>(props);
  useEffect(() => {
    previousConfig.current = props;
  });
  const [client, setClient] = useState<any | undefined>();

  useEffect(() => {
    // Skip creation of client if deferring, a value prop is passed, or config props are the same.
    if (
      !_PusherRuntime ||
      defer ||
      props.value ||
      (dequal(previousConfig.current, props) && client !== undefined)
    ) {
      return;
    }

    // @ts-ignore
    setClient(new _PusherRuntime(clientKey, config));
  }, [client, clientKey, props, defer]);

  // Only accept a meaningful value for channel prefix
  const validatedChannelPrefix =
    (channelPrefix?.length ?? 0) === 0 ? undefined : channelPrefix;

  if (validatedChannelPrefix !== undefined) {
    console.log("Using pusher channel prefix", validatedChannelPrefix);
  }

  return (
    <PusherContext.Provider
      value={{
        client,
        triggerEndpoint,
        channelPrefix: validatedChannelPrefix,
      }}
      children={children}
      {...props}
    />
  );
};

export const PusherProvider: React.FC<
  PusherProviderProps & { children: React.ReactNode }
> = (props) => <CorePusherProvider _PusherRuntime={Pusher} {...props} />;
