import * as React from "react";
import Collapse from "@mui/material/Collapse";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Checkbox, FormControlLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormTitle } from "../FormTitle/FormTitle";
import { Container, ExpandMore } from "../../layout/styleWrappers";
import { HorizontalLine } from "../../form/HorizontalLine";
import { useScreenDetection } from "../../../hooks/ScreenDetection/useScreenDetection";
import { PatientCrisisInfo } from "../../layout/PatientCrisisInfo";
import { PatientMergeDialog } from "../../../pages/PatientIndex/PatientMerge/PatientMergeDialog";

export interface PatientCardExtendedProps {
  patientName: string;
  patientDateOfBirth?: string | null;
  address?: string;
  isSelected: boolean;
  setIsSelected: () => void;
  patientAge: string;
  patientId?: string;
}

export const PatientCardExtended = (props: PatientCardExtendedProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    patientName,
    patientDateOfBirth,
    address,
    isSelected,
    setIsSelected,
    patientAge,
  } = props;
  const [expanded, setExpanded] = React.useState(false);

  const [showPatientMergeDialog, setShowPatientMergeDialog] =
    React.useState(false);

  const { isMobileView } = useScreenDetection();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const styles = {
    subtitle: {
      fontSize: theme.spacing(1.4),
      color: theme.palette.primary.hint,
    },
    border: {
      borderRight: `1px solid ${theme.palette.background.horizontalGray}`,
      paddingRight: theme.spacing(1.5),
      marginRight: theme.spacing(1.5),
    },
  };

  return (
    <Container>
      {showPatientMergeDialog && (
        <PatientMergeDialog
          closeMergeDialog={() => setShowPatientMergeDialog(false)}
        />
      )}
      <FormTitle
        useReducedTopPadding
        hasTitleBottomMargin={false}
        subtitleText={t("components.cardExtended.title")}
      />
      <Box
        sx={{
          padding: theme.spacing(3),
          border: `1px solid ${theme.palette.common.royalBlue}`,
          borderRadius: "6px 6px 0 0",
        }}
      >
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography sx={styles.subtitle}>
              {t("components.cardExtended.patientName")}
            </Typography>
            <Typography
              sx={{
                fontSize: theme.spacing(2.25),
                fontWeight: 600,
              }}
            >
              {patientName}
            </Typography>
          </Box>
          <FormControlLabel
            sx={{ mx: 0 }}
            control={
              <Checkbox
                name="selectPatient"
                checked={isSelected}
                sx={{ color: "primary.main" }}
              />
            }
            label={t("components.cardExtended.selectPatientLabel")}
            onChange={setIsSelected}
          />
        </Box>

        <HorizontalLine topMargin />
        <Box position="relative">
          <Box display="flex" flexWrap="wrap">
            <Box sx={styles.border} display="flex" flexDirection="column">
              <Typography sx={styles.subtitle}>
                {t("components.cardExtended.patientDateOfBirth")}
              </Typography>
              <Typography>{patientDateOfBirth}</Typography>
            </Box>
          </Box>

          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <HorizontalLine topMargin />
            <Box
              display="flex"
              sx={{ mt: 2, flexWrap: { sm: "wrap", md: "nowrap" } }}
            >
              <Box
                display="flex"
                flexDirection="column"
                sx={isMobileView ? {} : styles.border}
              >
                <Typography sx={styles.subtitle}>
                  {t("components.cardExtended.age")}
                </Typography>
                <Typography>
                  {patientAge ? patientAge : t("common.unknown")}
                </Typography>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                sx={{ mt: { sm: 1, md: 0 } }}
              >
                <Typography sx={styles.subtitle}>
                  {t("components.cardExtended.address")}
                </Typography>
                <Typography>{address}</Typography>
              </Box>
            </Box>
          </Collapse>

          <Box
            component={ExpandMore}
            sx={{
              display: "flex",
              position: "absolute",
              right: 0,
              bottom: 0,
              [theme.breakpoints.down("sm")]: {
                left: "100%",
              },
            }}
            role="button"
            data-testid="expand-more"
            isExpanded={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label={t("components.cardExtended.expandMoreLabel")}
          >
            <ExpandMoreIcon sx={{ color: "primary.main" }} />
          </Box>
        </Box>
      </Box>
      <PatientCrisisInfo />
    </Container>
  );
};
