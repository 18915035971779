import { useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import { api } from "~/api";
import {
  PopupDialog,
  PopupDialogTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { Base64FileUpload } from "~/components/form";
import { decodeBase64 } from "~/util";
import { AxiosResponse } from "axios";

export default function BulkUploadOrganisationUsers({
  open,
  onClose,
  onDownload,
  downloadedUserEmails,
  orgId,
  refetchOrg,
}: {
  onClose: () => void;
  open: boolean;
  onDownload: () => void;
  downloadedUserEmails: string[];
  orgId: string;
  refetchOrg: () => void;
}) {
  const [csvData, setCsvData] = useState<string[] | []>([]);
  const [dryRunResult, setDryRunResult] = useState<AxiosResponse<
    void,
    any
  > | null>(null);

  const [submitting, setSubmitting] = useState(false);

  const isValid = dryRunResult?.status === 204;
  return (
    <PopupDialog open={open} onClose={onClose} fullScreen>
      <Box sx={{ p: 4 }}>
        <PopupDialogTitle
          titleText={`Bulk Upload Organisation Users`}
          closeDialog={onClose}
        />
        <Button
          sx={{ mr: "1em" }}
          variant="outlined"
          onClick={onDownload}
          disabled={downloadedUserEmails.length > 0}
        >
          Download
        </Button>
        <Base64FileUpload
          accept={".csv"}
          onDone={async (uploadedFiles) => {
            if (uploadedFiles.length === 1) {
              const csvData = decodeBase64(
                (uploadedFiles[0].base64 as string).split(",")[1]!,
              );

              const getCsvData = csvData.split(",");

              const result =
                await api.organisations.bulkUploadUsersToOrganisation(
                  orgId,
                  getCsvData,
                  true,
                );

              setCsvData(getCsvData ?? []);
              setDryRunResult(result);
            }
          }}
          label={"Upload a CSV"}
        />
        {dryRunResult && (
          <Box
            sx={{
              mt: "1em",
              mb: "1em",
              border: isValid ? "1px solid black" : "1px solid red",
              padding: "0.5em",
              width: "fit-content",
            }}
          >
            <Typography>{isValid ? "Valid File" : "Errors:"}</Typography>
            <Typography>{(dryRunResult.data as any).reason}</Typography>
          </Box>
        )}

        {isValid && (
          <Button
            variant="contained"
            disabled={submitting}
            onClick={async () => {
              setSubmitting(true);
              const result =
                await api.organisations.bulkUploadUsersToOrganisation(
                  orgId,
                  csvData,
                );

              setSubmitting(false);
              setCsvData([]);
              setDryRunResult(null);
              onClose();
              refetchOrg();

              if (result.status === 204) {
                renderSuccessToast({
                  message: "Users successfully added to this organisation",
                });
              } else {
                renderErrorToast({
                  message: `Failed to add users to this organisation: ${
                    (result.data as any).reason
                  }`,
                });
              }
            }}
          >
            Submit
          </Button>
        )}
      </Box>
    </PopupDialog>
  );
}
