import { boolean, string } from "yup";
import { nonEmptyString } from "../shared";

export const requiredStringBasedOnCheckbox = (
  selectedField: string,
  requiredMessage: string,
) =>
  string()
    .default(null)
    .nullable()
    .when(selectedField, {
      is: true,
      then: () => nonEmptyString.required(requiredMessage),
      otherwise: () => string().default(null).nullable(),
    });
export const requiredStringWhen = (field: string, requiredMessage: string) =>
  string()
    .nullable()
    .default(null)
    .when(field, {
      is: true,
      then: () =>
        // @ts-ignore
        nonEmptyString.required(requiredMessage),
      otherwise: () => string().nullable(),
    });
export const requiredBooleanWhen = (
  field: string,
  requiredMessage: string,
  truthyStringValue?: string,
) =>
  boolean()
    .default(null)
    .when(field, {
      is: (val: string | boolean) =>
        (truthyStringValue && val === truthyStringValue) || val === true,
      then: () =>
        // @ts-ignore
        boolean().required(requiredMessage),
      otherwise: () => boolean(),
    });
const phoneNumberRegex =
  /^((\(?0\d{4}\)?\s?\d{3}\s?\d{3})|(\(?0\d{3}\)?\s?\d{3}\s?\d{4})|(\(?0\d{2}\)?\s?\d{4}\s?\d{4}))(\s?\#(\d{4}|\d{3}))?$/;
export const phoneNumber = string()
  .nullable()
  .default(null)
  .transform(function (value, originalValue) {
    if (!value) {
      return null;
    }
    return originalValue;
  })
  .matches(phoneNumberRegex, "Please enter a valid phone number");

export const optionalEmail = string()
  .email("Please enter a valid email")
  .default(null)
  .transform(function (value, originalValue) {
    if (!value) {
      return null;
    }
    return originalValue;
  })
  .nullable();
