import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import {
  Box,
  Divider,
  FormControlLabel,
  Radio,
  RadioGroup,
  useTheme,
} from "@mui/material";
import {
  Dropdown,
  FormTitle,
  ReadOnlyContent,
  renderErrorToast,
  FormLabel,
  HelperText,
  Banner,
  BannerList,
  renderSuccessToast,
} from "~/components/design-system";
import { Container } from "~/components/layout/styleWrappers";
import {
  MhaStatus,
  MhaStatusState,
  DischargeReason,
  DetainedStatuses,
} from "@aspire/common/types/patientState/PatientState";
import {
  FormFooterSection,
  DateTimeFormField,
  HospitalPicker,
  DateFormField,
  ukTimezoneHackeryOnBrowserLocalValue,
} from "~/components/form";
import {
  getExpiryDate,
  getRenewalDates,
} from "@aspire/common/util/patientstate";
import { api } from "~/api";
import dayjs from "dayjs";
import { routeFns } from "../../routes";
import { formManualEventSchema } from "@aspire/common/schemas/patientStateEvent";
import { PatientStateWithContext } from "@aspire/common/types/patientState/PatientStateWithContext";
import { ConfirmationModal } from "../ConfirmationModal";
import { EditMhaStatusSuccessModal } from "./EditMhaStatusSuccessModal";
import { FormContextData } from "@aspire/common/types/formContextData";
import _ from "lodash";
import { useScreenDetection } from "~/hooks/ScreenDetection/useScreenDetection";
import { UpdatePatientStateWithManualEventRequest } from "@aspire/common/types/patientState/patientStateRequests";

const getRenewalsDropdownOptions = () => {
  return [...Array(100).keys()].map((i) => {
    return {
      value: i + 1,
      label: (i + 1).toString(),
    };
  });
};

const renewalDropDownOptions = getRenewalsDropdownOptions();

export function EditMhaStatus({
  state,
  isScrutiny,
  hasFormWithApplicationOrMedRec,
  formContext,
}: {
  state: PatientStateWithContext;
  isScrutiny: boolean;
  hasFormWithApplicationOrMedRec?: boolean;
  formContext?: FormContextData;
}) {
  const navigate = useNavigate();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMobileView } = useScreenDetection();
  const location = useLocation();
  const qs = new URLSearchParams(location.search);

  const [confirmFn, setConfirmFn] = useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);
  const [successFn, setSuccessFn] = useState<{
    confirmFn: () => void;
    title: string;
    body: string;
  } | null>(null);

  const { mhaStatus, hospital, episodeOfCare } = state.state;

  const hasRenewals = (mhaStatus: MhaStatusState) => {
    if (
      mhaStatus.status === MhaStatus.Section17A ||
      mhaStatus.status === MhaStatus.Section3
    ) {
      if (
        mhaStatus.numberOfRenewals === undefined ||
        mhaStatus.numberOfRenewals === 0
      ) {
        return false;
      }
      return true;
    }
    return undefined;
  };

  const getNumberOfRenewals = (
    status: MhaStatus,
    hasRenewals: boolean | undefined,
    numberOfRenewals: number | undefined,
  ) => {
    if (status === MhaStatus.Section17A || status === MhaStatus.Section3) {
      return hasRenewals === true ? numberOfRenewals : 0;
    }
    return undefined;
  };

  const getOriginalRenewalExpiry = (mhaStatus: MhaStatusState) => {
    if (
      (mhaStatus.status === MhaStatus.Section17A ||
        mhaStatus.status === MhaStatus.Section3) &&
      hasRenewals(mhaStatus) &&
      mhaStatus.initialStartDateTime
    ) {
      const { originalRenewalEndDate } = getRenewalDates(
        mhaStatus.initialStartDateTime,
        0,
      );
      return originalRenewalEndDate;
    }
    return undefined;
  };

  const initialData = {
    type: "manualEvent" as const,
    status:
      mhaStatus.status === MhaStatus.Expired ||
      mhaStatus.status === MhaStatus.Incomplete
        ? MhaStatus.Unknown
        : mhaStatus.status,
    startDateTime: mhaStatus.startDateTime || undefined,
    expiryDateTime: mhaStatus.expiryDateTime || undefined,
    initialStartDateTime: mhaStatus.initialStartDateTime || undefined,
    numberOfRenewals: mhaStatus.numberOfRenewals || undefined,
    hasRenewals: hasRenewals(mhaStatus),
    ...(hospital
      ? {
          hospital: {
            name: hospital.name,
            address: hospital.address,
            postalCode: hospital.postalCode,
            odsCode: hospital.odsCode || undefined,
            isConfirmed: hospital.name ? true : false,
          },
        }
      : { hospital: { name: "", isConfirmed: false } }),
    episodeOfCareDischargeDateTime:
      episodeOfCare?.dischargeDateTime || undefined,
    episodeOfCareDischargeReason: episodeOfCare?.dischargeReason || undefined,
    originalRenewalExpiry: getOriginalRenewalExpiry(mhaStatus),
  };

  const updateMHAStatus = async (
    isOriginalFormValues: boolean,
    cleanedData: UpdatePatientStateWithManualEventRequest,
  ) => {
    if (!isOriginalFormValues) {
      const result = await api.patients.updatePatientMhaStateWithManualEvent(
        state.patientId,
        cleanedData,
      );
      if (result.status === 204) {
        renderSuccessToast({
          message: t("pages.editMHAStatus.successMessage"),
        });
      } else {
        renderErrorToast({
          message: t("pages.editMHAStatus.errorMessage"),
        });
        return;
      }
    }
  };

  const scrutinyFlowConfirmAndSubmit = async (
    isOriginalFormValues: boolean,
    cleanedData: UpdatePatientStateWithManualEventRequest,
  ) => {
    setConfirmFn({
      message: hasFormWithApplicationOrMedRec
        ? t("pages.editMHAStatus.scrutinyConfirmModalAdmission")
        : t("pages.editMHAStatus.scrutinyConfirmModalStandalone"),
      confirmFn: async () => {
        await updateMHAStatus(isOriginalFormValues, cleanedData);
        const result = await api.work.finalise(
          formContext!.activeTeamworkWorkItem!.id,
        );
        if (result.status === 204) {
          const itemName = qs.get("itemName") ?? "item";
          navigate(
            routeFns.formContextPageSuccessDialog(
              formContext!.id,
              formContext!.patientId,
              {
                title: "Administrative scrutiny marked complete",
                message: hasFormWithApplicationOrMedRec
                  ? "You have successfully marked administrative scrutiny of this admission complete"
                  : `You have successfully marked administrative scrutiny of this ${itemName} complete`,
              },
            ),
          );
        } else {
          renderErrorToast({
            message: "Error marking administrative scrutiny as complete",
          });
        }
      },
    });
  };

  return (
    <Box
      display={"block"}
      sx={{
        marginBottom: theme.spacing(10),
      }}
    >
      {isScrutiny && (
        <Banner
          bannerType={BannerList.WARNING}
          body={[t("pages.editMHAStatus.scrutinyWarningMessage")]}
          sx={{
            marginBottom: theme.spacing(2),
          }}
        />
      )}
      <FormTitle
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
        titleText={t("pages.editMHAStatus.updateMHAStatus")}
        hasContainerMarginBottom={isMobileView ? false : true}
      />

      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}

      {successFn && (
        <EditMhaStatusSuccessModal
          title={successFn.title}
          body={successFn.body}
          confirmFn={successFn.confirmFn}
          closeFn={() => setSuccessFn(null)}
        />
      )}

      <Formik<any>
        validationSchema={formManualEventSchema}
        initialValues={initialData}
        onSubmit={async (values, { setSubmitting }) => {
          const cleanedData: UpdatePatientStateWithManualEventRequest = {
            type: "manualEvent" as const,
            status: values.status,
            startDateTime: values.startDateTime
              ? dayjs(values.startDateTime).toISOString()
              : undefined,
            initialStartDateTime: values.initialStartDateTime
              ? dayjs(values.initialStartDateTime).toISOString()
              : undefined,
            numberOfRenewals: getNumberOfRenewals(
              values.status,
              values.hasRenewals,
              values.numberOfRenewals,
            ),
            episodeOfCareDischargeDateTime:
              values.episodeOfCareDischargeDateTime || undefined,
            episodeOfCareDischargeReason:
              values.episodeOfCareDischargeReason || undefined,
            ...(values.hospital &&
              values.hospital.name && {
                hospital: {
                  name: values.hospital.name,
                  address: values.hospital.address,
                  postalCode: values.hospital.postalCode,
                  odsCode: values.hospital.odsCode || undefined,
                },
              }),
          };
          if (!isScrutiny) {
            setConfirmFn({
              message: t(`pages.editMHAStatus.confirmAction`),
              confirmFn: async () => {
                setSubmitting(true);
                const result =
                  await api.patients.updatePatientMhaStateWithManualEvent(
                    state.patientId,
                    cleanedData,
                  );
                setSubmitting(false);

                if (result.status === 204) {
                  setSuccessFn({
                    title: t(`pages.editMHAStatus.success`),
                    body: t(`pages.editMHAStatus.successModalBody`),
                    confirmFn: () => {
                      setSuccessFn(null);
                      navigate(routeFns.patientHome(state.patientId));
                    },
                  });
                } else {
                  renderErrorToast({
                    message: t("pages.editMHAStatus.errorMessage"),
                  });
                }

                setConfirmFn(null);
              },
            });
          } else {
            // date/ time pickers set the value to null so need to remove before checking for differences
            const isOriginalFormValues = _.isEqual(
              _.omitBy(values, _.isNil),
              _.omitBy(initialData, _.isNil),
            );

            setSubmitting(true);
            await scrutinyFlowConfirmAndSubmit(
              isOriginalFormValues,
              cleanedData,
            );
            setSubmitting(false);
          }
        }}
      >
        {(formikData) => {
          const {
            values,
            setValues,
            errors,
            touched,
            submitForm,
            isSubmitting,
            setFieldTouched,
            handleBlur,
            setTouched,
            dirty,
          } = formikData;

          const fieldProps = {
            context: {},
            values,
            validationSchema: formManualEventSchema,
            setValues,
            errors,
            touched,
            handleBlur,
            setFieldTouched,
          };

          const isSection5_4OrSection5_2OrSection4 =
            values.status === MhaStatus.Section5_4 ||
            values.status === MhaStatus.Section5_2 ||
            values.status === MhaStatus.Section4;

          const isSection2 = values.status === MhaStatus.Section2;

          const isSection3OrCTO =
            values.status === MhaStatus.Section17A ||
            values.status === MhaStatus.Section3;

          const isNotDetained = values.status === MhaStatus.NotDetained;

          const showDivider = DetainedStatuses.includes(values.status)
            ? true
            : false;

          const showHospital = DetainedStatuses.includes(values.status)
            ? true
            : false;

          const getStartDateLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.ctoStartDate")
              : t("pages.editMHAStatus.section3StartDate");
          };

          const getEndDateLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.ctoExpiryDate")
              : t("pages.editMHAStatus.section3ExpiryDate");
          };

          const getOriginalStartDateLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.originalCTOStartDate")
              : t("pages.editMHAStatus.originalSection3StartDate");
          };

          const getOriginalEndDateLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.originalCTOExpiryDate")
              : t("pages.editMHAStatus.originalSection3ExpiryDate");
          };

          const getRenewalLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.hasRenewalCTO")
              : t("pages.editMHAStatus.hasRenewalSection3");
          };

          const getRenewalDropdownLabel = (status: MhaStatus): string => {
            return status === MhaStatus.Section17A
              ? t("pages.editMHAStatus.extensions")
              : t("pages.editMHAStatus.renewals");
          };

          const getReadOnlyContent = (
            label: string,
            content: string | undefined | null,
            format: string,
            testId: string,
          ) => {
            const style = {
              marginLeft: isMobileView ? 0 : theme.spacing(6),
            };
            return (
              <Box
                display="flex"
                flexDirection="column"
                sx={style}
                data-testid={testId}
              >
                <ReadOnlyContent
                  label={label}
                  content={[formatDateTime(content, format)]}
                  isActive
                  useMarginTop={true}
                />
              </Box>
            );
          };

          const setExpiryDateTime = (
            status: MhaStatus,
            startDateTime: string | undefined | null,
            numberOfRenewals?: number,
          ) => {
            if (startDateTime && dayjs(startDateTime).isValid()) {
              return getExpiryDate({
                MhaStatusType: status,
                effectiveDateTime: startDateTime,
                numberOfRenewals: numberOfRenewals,
              });
            }
            return undefined;
          };

          const getSelectedStatus = (status: MhaStatus) => {
            if (
              status === MhaStatus.Expired ||
              status === MhaStatus.Incomplete
            ) {
              return MhaStatus.Unknown;
            }
            return status;
          };

          const formatDateTime = (
            dateTime: string | undefined | null,
            format: string,
          ) => {
            return dateTime && dayjs(dateTime).isValid()
              ? dayjs(dateTime).tz("Europe/London").format(format)
              : "-- -- --";
          };

          return (
            <>
              <Container
                sx={isMobileView ? { marginTop: theme.spacing(2) } : {}}
              >
                <Dropdown
                  label={t("pages.editMHAStatus.currentMHAStatus")}
                  name="mhaStatus"
                  selectedValue={getSelectedStatus(values.status)}
                  values={[
                    {
                      value: MhaStatus.Unknown,
                      label: t("pages.editMHAStatus.unknown"),
                    },
                    {
                      value: MhaStatus.Section5_4,
                      label: t("pages.editMHAStatus.section5_4"),
                    },
                    {
                      value: MhaStatus.Section5_2,
                      label: t("pages.editMHAStatus.section5_2"),
                    },
                    {
                      value: MhaStatus.Section4,
                      label: t("pages.editMHAStatus.section4"),
                    },
                    {
                      value: MhaStatus.Section2,
                      label: t("pages.editMHAStatus.section2"),
                    },
                    {
                      value: MhaStatus.Section3,
                      label: t("pages.editMHAStatus.section3"),
                    },
                    {
                      value: MhaStatus.Section17A,
                      label: t("pages.editMHAStatus.cto"),
                    },
                    {
                      value: MhaStatus.NotDetained,
                      label: t("pages.editMHAStatus.notDetained"),
                    },
                  ]}
                  onChange={(value) => {
                    // reset everything
                    setTouched({}, false);
                    setValues({
                      type: "manualEvent" as const,
                      status: value as MhaStatus,
                      startDateTime: undefined,
                      expiryDateTime: undefined,
                      numberOfRenewals: undefined,
                      hasRenewals: undefined,
                      episodeOfCareDischargeDateTime: undefined,
                      episodeOfCareDischargeReason: undefined,
                      hospital: undefined,
                      originalRenewalExpiry: undefined,
                      initialStartDateTime: undefined,
                    });
                  }}
                />

                <>
                  {showDivider && (
                    <Divider
                      sx={{
                        marginTop: theme.spacing(2),
                        marginBottom: theme.spacing(2),
                      }}
                    />
                  )}

                  {isSection5_4OrSection5_2OrSection4 && (
                    <Box
                      display={"flex"}
                      flexDirection={isMobileView ? "column" : "row"}
                      sx={
                        isMobileView ? { marginBottom: theme.spacing(3) } : {}
                      }
                    >
                      <Box display="flex" flexDirection="column">
                        <DateTimeFormField
                          field={{
                            label: t("pages.editMHAStatus.startDateTime"),
                            type: "date-time",
                            field: "startDateTime",
                            showDateTimeMessage: false,
                            maximum: () => dayjs(),
                          }}
                          fieldProps={{
                            ...fieldProps,
                            setValues: (values: any) => {
                              setValues({
                                ...values,
                                startDateTime: values.startDateTime,
                                expiryDateTime: setExpiryDateTime(
                                  values.status,
                                  values.startDateTime,
                                ),
                              });
                            },
                          }}
                        />
                      </Box>

                      {getReadOnlyContent(
                        t("pages.editMHAStatus.endDateTime"),
                        values.expiryDateTime,
                        "DD/MM/YY - HH:mm",
                        "endDateTime",
                      )}
                    </Box>
                  )}

                  {isSection2 && (
                    <Box
                      display={"flex"}
                      flexDirection={isMobileView ? "column" : "row"}
                      sx={
                        isMobileView ? { marginBottom: theme.spacing(3) } : {}
                      }
                    >
                      <Box display="flex" flexDirection="column">
                        <DateFormField
                          field={{
                            label: t("pages.editMHAStatus.startDate"),
                            type: "date",
                            field: "startDateTime",
                            maximum: () => dayjs(),
                          }}
                          fieldProps={{
                            ...fieldProps,
                            setValues: (values: any) => {
                              let utcStartDate;
                              if (
                                values.startDateTime &&
                                dayjs(values.startDateTime).isValid()
                              ) {
                                utcStartDate =
                                  ukTimezoneHackeryOnBrowserLocalValue(
                                    dayjs(values.startDateTime),
                                  );
                              }
                              setValues({
                                ...values,
                                startDateTime: utcStartDate,
                                expiryDateTime: setExpiryDateTime(
                                  values.status,
                                  utcStartDate,
                                ),
                              });
                            },
                          }}
                        />
                      </Box>

                      {getReadOnlyContent(
                        t("pages.editMHAStatus.endDate"),
                        values.expiryDateTime,
                        "DD/MM/YY",
                        "endDate",
                      )}
                    </Box>
                  )}

                  {isSection3OrCTO && (
                    <Box display="flex" flexDirection="column">
                      <Box
                        display="flex"
                        sx={{
                          marginBottom: theme.spacing(2),
                        }}
                      >
                        <Box
                          display="flex"
                          sx={{
                            flex: 1,
                          }}
                        >
                          <Box>
                            <FormLabel label={getRenewalLabel(values.status)} />
                            <RadioGroup
                              aria-labelledby="radio-buttons-group-label"
                              name="hasRenewals"
                              defaultValue={null}
                              row={true}
                            >
                              <FormControlLabel
                                control={
                                  <Radio sx={{ color: "primary.main" }} />
                                }
                                label={t("common.no")}
                                value={false}
                                checked={values.hasRenewals === false}
                                name="hasRenewals"
                                onChange={(value) => {
                                  // reset when moving from yes to no
                                  setTouched({}, false);
                                  setValues({
                                    ...values,
                                    hasRenewals: false,
                                    startDateTime: undefined,
                                    expiryDateTime: undefined,
                                    numberOfRenewals: undefined,
                                    episodeOfCareDischargeDateTime: undefined,
                                    episodeOfCareDischargeReason: undefined,
                                    hospital: undefined,
                                    originalRenewalExpiry: undefined,
                                    initialStartDateTime: undefined,
                                  });
                                }}
                              ></FormControlLabel>
                              <FormControlLabel
                                control={
                                  <Radio sx={{ color: "primary.main" }} />
                                }
                                label={t("common.yes")}
                                value={true}
                                checked={values.hasRenewals === true}
                                name="hasRenewals"
                                onChange={(value) => {
                                  // reset when moving from yes to no
                                  setTouched({}, false);
                                  setValues({
                                    ...values,
                                    hasRenewals: true,
                                    startDateTime: undefined,
                                    expiryDateTime: undefined,
                                    numberOfRenewals: undefined,
                                    episodeOfCareDischargeDateTime: undefined,
                                    episodeOfCareDischargeReason: undefined,
                                    hospital: undefined,
                                    originalRenewalExpiry: undefined,
                                    initialStartDateTime: undefined,
                                  });
                                }}
                              />
                            </RadioGroup>
                            {touched.hasRenewals && errors.hasRenewals && (
                              <HelperText
                                errorMessage={errors.hasRenewals as string}
                              />
                            )}
                          </Box>
                        </Box>
                      </Box>

                      {values.hasRenewals === false && (
                        <Box
                          display="flex"
                          flexDirection={isMobileView ? "column" : "row"}
                          sx={
                            isMobileView
                              ? { marginBottom: theme.spacing(3) }
                              : { marginBottom: theme.spacing(2) }
                          }
                        >
                          <Box
                            display="flex"
                            sx={{
                              width: theme.spacing(30),
                            }}
                          >
                            <DateFormField
                              field={{
                                type: "date",
                                field: "initialStartDateTime",
                                label: getStartDateLabel(values.status),
                                maximum: () => dayjs(),
                              }}
                              fieldProps={{
                                ...fieldProps,
                                setValues: (values: any) => {
                                  setValues({
                                    ...values,
                                    initialStartDateTime:
                                      values.initialStartDateTime,
                                    startDateTime: values.initialStartDateTime,
                                    expiryDateTime: setExpiryDateTime(
                                      values.status,
                                      values.initialStartDateTime,
                                      0,
                                    ),
                                  });
                                },
                              }}
                            />
                          </Box>
                          <Box
                            display="flex"
                            sx={{
                              minWidth: theme.spacing(32),
                            }}
                          >
                            {getReadOnlyContent(
                              getEndDateLabel(values.status),
                              values.expiryDateTime,
                              "DD/MM/YY",
                              "expiryDateTime",
                            )}
                          </Box>
                        </Box>
                      )}

                      {values.hasRenewals === true && (
                        <Box
                          sx={{
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          <Box
                            display="flex"
                            sx={{
                              marginBottom: theme.spacing(2),
                            }}
                          >
                            <Box
                              sx={{
                                minWidth: theme.spacing(12),
                              }}
                            >
                              <Dropdown
                                label={getRenewalDropdownLabel(values.status)}
                                name="mhaStatus"
                                selectedValue={
                                  values.numberOfRenewals === undefined
                                    ? ""
                                    : values.numberOfRenewals
                                }
                                values={renewalDropDownOptions}
                                onChange={(value) => {
                                  const numberOfRenewals = value as number;
                                  const {
                                    originalRenewalEndDate,
                                    currentRenewalStartDate,
                                    currentRenewalEndDate,
                                  } = getRenewalDates(
                                    values.initialStartDateTime,
                                    numberOfRenewals,
                                  );
                                  setTouched({}, false);
                                  setValues({
                                    ...values,
                                    numberOfRenewals: value,
                                    originalRenewalExpiry:
                                      originalRenewalEndDate,
                                    expiryDateTime: currentRenewalEndDate,
                                    startDateTime: currentRenewalStartDate,
                                  });
                                }}
                                errorMessage={
                                  touched.numberOfRenewals &&
                                  errors.numberOfRenewals
                                    ? (errors.numberOfRenewals as string)
                                    : undefined
                                }
                              />
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection={isMobileView ? "column" : "row"}
                            sx={
                              isMobileView
                                ? { marginBottom: theme.spacing(3) }
                                : { marginBottom: theme.spacing(2) }
                            }
                          >
                            <Box
                              display="flex"
                              sx={{
                                minWidth: theme.spacing(32),
                              }}
                            >
                              <DateFormField
                                field={{
                                  type: "date",
                                  field: "initialStartDateTime",
                                  label: getOriginalStartDateLabel(
                                    values.status,
                                  ),
                                  maximum: () => dayjs(),
                                  disabled: !values.numberOfRenewals,
                                }}
                                fieldProps={{
                                  ...fieldProps,
                                  setValues: (values: any) => {
                                    const {
                                      originalRenewalEndDate,
                                      currentRenewalStartDate,
                                      currentRenewalEndDate,
                                    } = getRenewalDates(
                                      values.initialStartDateTime,
                                      values.numberOfRenewals,
                                    );

                                    setValues({
                                      ...values,
                                      initialStartDateTime:
                                        values.initialStartDateTime,
                                      originalRenewalExpiry:
                                        originalRenewalEndDate,
                                      expiryDateTime: currentRenewalEndDate,
                                      startDateTime: currentRenewalStartDate,
                                    });
                                  },
                                }}
                              />
                            </Box>
                            <Box
                              display="flex"
                              sx={{
                                minWidth: theme.spacing(32),
                              }}
                            >
                              {getReadOnlyContent(
                                getOriginalEndDateLabel(values.status),
                                values.originalRenewalExpiry,
                                "DD/MM/YY",
                                "originalRenewalExpiry",
                              )}
                            </Box>
                          </Box>
                          <Box
                            display="flex"
                            flexDirection={isMobileView ? "column" : "row"}
                            sx={
                              isMobileView
                                ? { marginBottom: theme.spacing(3) }
                                : { marginBottom: theme.spacing(2) }
                            }
                          >
                            <Box
                              display="flex"
                              sx={{
                                minWidth: theme.spacing(32),
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                sx={
                                  isMobileView
                                    ? { marginBottom: theme.spacing(3) }
                                    : { marginLeft: "none" }
                                }
                                data-testid="currentStartDate"
                              >
                                <ReadOnlyContent
                                  label={getStartDateLabel(values.status)}
                                  content={[
                                    formatDateTime(
                                      values.startDateTime,
                                      "DD/MM/YY",
                                    ),
                                  ]}
                                  isActive
                                />
                                {touched.startDateTime &&
                                  errors.startDateTime && (
                                    <HelperText
                                      errorMessage={
                                        errors.startDateTime as string
                                      }
                                    />
                                  )}
                              </Box>
                            </Box>
                            <Box
                              display="flex"
                              sx={{
                                minWidth: theme.spacing(32),
                              }}
                            >
                              <Box
                                display="flex"
                                flexDirection="column"
                                sx={
                                  isMobileView
                                    ? {}
                                    : { marginLeft: theme.spacing(6) }
                                }
                                data-testid="currentEndDate"
                              >
                                <ReadOnlyContent
                                  label={getEndDateLabel(values.status)}
                                  content={[
                                    formatDateTime(
                                      values.expiryDateTime,
                                      "DD/MM/YY",
                                    ),
                                  ]}
                                  isActive
                                />
                                {touched.expiryDateTime &&
                                  errors.expiryDateTime && (
                                    <HelperText
                                      errorMessage={
                                        errors.expiryDateTime as string
                                      }
                                    />
                                  )}
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  )}

                  {isNotDetained && (
                    <>
                      <Box
                        display={"flex"}
                        flexDirection="row"
                        sx={{
                          marginBottom: theme.spacing(2),
                        }}
                      >
                        <Box display="flex" flexDirection="column">
                          <FormLabel
                            label={t("pages.editMHAStatus.notDetainedReason")}
                          />
                          <RadioGroup
                            aria-labelledby="radio-buttons-group-label"
                            name="episodeOfCareDischargeReason"
                            defaultValue={null}
                          >
                            <FormControlLabel
                              control={<Radio sx={{ color: "primary.main" }} />}
                              label={t(
                                "pages.editMHAStatus.dischargedSection23",
                              )}
                              value={DischargeReason.Section23}
                              checked={
                                values.episodeOfCareDischargeReason ===
                                DischargeReason.Section23
                              }
                              name="episodeOfCareDischargeReason"
                              onChange={(value) => {
                                // reset when moving between discharge reason
                                setTouched({}, false);
                                setValues({
                                  ...values,
                                  episodeOfCareDischargeReason:
                                    DischargeReason.Section23,
                                  hasRenewals: false,
                                  startDateTime: undefined,
                                  expiryDateTime: undefined,
                                  numberOfRenewals: undefined,
                                  episodeOfCareDischargeDateTime: undefined,
                                  hospital: undefined,
                                  originalRenewalExpiry: undefined,
                                  initialStartDateTime: undefined,
                                });
                              }}
                            ></FormControlLabel>
                            <FormControlLabel
                              control={<Radio sx={{ color: "primary.main" }} />}
                              label={t(
                                "pages.editMHAStatus.dischargedHoldingPowers",
                              )}
                              value={DischargeReason.HoldingPowersDischarge}
                              checked={
                                values.episodeOfCareDischargeReason ===
                                DischargeReason.HoldingPowersDischarge
                              }
                              name="episodeOfCareDischargeReason"
                              onChange={(value) => {
                                // reset when moving between discharge reason
                                setTouched({}, false);
                                setValues({
                                  ...values,
                                  episodeOfCareDischargeReason:
                                    DischargeReason.HoldingPowersDischarge,
                                  hasRenewals: false,
                                  startDateTime: undefined,
                                  expiryDateTime: undefined,
                                  numberOfRenewals: undefined,
                                  episodeOfCareDischargeDateTime: undefined,
                                  hospital: undefined,
                                  originalRenewalExpiry: undefined,
                                  initialStartDateTime: undefined,
                                });
                              }}
                            ></FormControlLabel>
                            <FormControlLabel
                              control={<Radio sx={{ color: "primary.main" }} />}
                              label={t(
                                "pages.editMHAStatus.dischargedTribunal",
                              )}
                              value={DischargeReason.TribunalOrNearestRelative}
                              checked={
                                values.episodeOfCareDischargeReason ===
                                DischargeReason.TribunalOrNearestRelative
                              }
                              name="episodeOfCareDischargeReason"
                              onChange={(value) => {
                                // reset when moving between discharge reason
                                setTouched({}, false);
                                setValues({
                                  ...values,
                                  episodeOfCareDischargeReason:
                                    DischargeReason.TribunalOrNearestRelative,
                                  hasRenewals: false,
                                  startDateTime: undefined,
                                  expiryDateTime: undefined,
                                  numberOfRenewals: undefined,
                                  episodeOfCareDischargeDateTime: undefined,
                                  hospital: undefined,
                                  originalRenewalExpiry: undefined,
                                  initialStartDateTime: undefined,
                                });
                              }}
                            ></FormControlLabel>
                            <FormControlLabel
                              control={<Radio sx={{ color: "primary.main" }} />}
                              label={t("pages.editMHAStatus.other")}
                              value={DischargeReason.Other}
                              checked={
                                values.episodeOfCareDischargeReason ===
                                DischargeReason.Other
                              }
                              name="episodeOfCareDischargeReason"
                              onChange={(value) => {
                                // reset when moving between discharge reason
                                setTouched({}, false);
                                setValues({
                                  ...values,
                                  episodeOfCareDischargeReason:
                                    DischargeReason.Other,
                                  hasRenewals: false,
                                  startDateTime: undefined,
                                  expiryDateTime: undefined,
                                  numberOfRenewals: undefined,
                                  episodeOfCareDischargeDateTime: undefined,
                                  hospital: undefined,
                                  originalRenewalExpiry: undefined,
                                  initialStartDateTime: undefined,
                                });
                              }}
                            ></FormControlLabel>
                          </RadioGroup>
                          {touched.episodeOfCareDischargeReason &&
                            errors.episodeOfCareDischargeReason && (
                              <HelperText
                                errorMessage={
                                  errors.episodeOfCareDischargeReason as string
                                }
                              />
                            )}
                        </Box>
                      </Box>
                      {isNotDetained && (
                        <Box
                          display={"flex"}
                          flexDirection="row"
                          sx={{
                            marginBottom: theme.spacing(2),
                          }}
                        >
                          <Box display="flex" flexDirection="column">
                            <DateTimeFormField
                              field={{
                                label: t(
                                  "pages.editMHAStatus.dateTimeDischarge",
                                ),
                                type: "date-time",
                                field: "episodeOfCareDischargeDateTime",
                                showDateTimeMessage: false,
                                maximum: () => dayjs(),
                              }}
                              fieldProps={fieldProps}
                            />
                          </Box>
                        </Box>
                      )}
                    </>
                  )}
                </>
                {showHospital && (
                  <>
                    <Divider
                      sx={{
                        marginBottom: theme.spacing(2),
                      }}
                    />
                    <div>
                      <HospitalPicker
                        field={{
                          type: "hospital-picker",
                          field: "hospital",
                          label:
                            values.status === MhaStatus.NotDetained
                              ? t("pages.editMHAStatus.previousHospital")
                              : t("pages.editMHAStatus.hospital"),
                          tooltip: t("pages.editMHAStatus.hospitalToolTip"),
                        }}
                        fieldProps={fieldProps}
                      />
                    </div>
                  </>
                )}
              </Container>
              <FormFooterSection
                customFooterBackgroundColor={theme.palette.common.white}
                onSave={submitForm}
                saveLabel={
                  isScrutiny
                    ? t("pages.editMHAStatus.continue")
                    : t("pages.editMHAStatus.updateStatusBtn")
                }
                discardLabel={t("pages.editMHAStatus.discardBtn")}
                onCancel={() => {
                  return isScrutiny
                    ? navigate(
                        routeFns.formContextPage(
                          formContext!.id,
                          state.patientId,
                        ),
                      )
                    : navigate(routeFns.patientHome(state.patientId));
                }}
                disableDiscard={isSubmitting}
                disableSubmit={
                  isScrutiny ? isSubmitting : isSubmitting || !dirty
                }
                minHeight
              />
            </>
          );
        }}
      </Formik>
    </Box>
  );
}
