import React, { useMemo } from "react";
import { InferType, object, mixed } from "yup";
import { Box, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { v4 } from "uuid";
import { Formik } from "formik";
import { api } from "../../api";
import {
  BranchFormField,
  FormFooterSection,
  NameAddressFormField,
  TextboxFormField,
} from "~/components/form";
import { routeFns } from "../../routes";
import {
  isoDateString,
  nonEmptyString,
  nameAndAddress,
} from "@aspire/common/schemas/shared";
import { useTranslation } from "react-i18next";
import {
  Banner,
  BannerList,
  FormTitle,
  renderErrorToast,
  renderSuccessToast,
} from "~/components/design-system";
import { Container } from "~/components/layout/styleWrappers";
import { ConfirmationModal } from "../ConfirmationModal";
import { editPatientDialog } from "./PatientEditPage";
import { formValidationErrorMessages } from "@aspire/common/schemas/shared";

const patientCreateSchema = object({
  givenName: nonEmptyString
    .required("Please enter patient's forename")
    .max(255, formValidationErrorMessages.tooManyCharactersError),
  familyNameAndAddress: nameAndAddress({
    nameRequiredError: "Please enter patient's surname",
    addressRequiredError: "Please enter patient's home address",
  }),
  isPatientDateOfBirth: mixed()
    .nullable()
    .oneOf([true, false, null], "Please select an option")
    .default(null)
    .required("Please select an option"),
  dateOfBirth: isoDateString.default(null).when("isPatientDateOfBirth", {
    is: true,
    then: () => isoDateString.required("Please enter patient's date of birth"),
  }),
});

export type PatientCreateSchema = InferType<typeof patientCreateSchema>;

export type PatientCreateEditProps = {
  refetchAndRedirect?: () => void;
  setShowUpdateSuccessDialog?: (v: boolean) => void;
  exitPatientCreate: () => void;
  initialState: PatientCreateSchema | null;
  mode: "create" | "edit" | editPatientDialog;
  expectedVersion?: number;
  existingPatientId?: string;
};

export function PatientCreateEdit({
  refetchAndRedirect,
  setShowUpdateSuccessDialog,
  initialState,
  exitPatientCreate,
  mode,
  expectedVersion,
  existingPatientId,
}: PatientCreateEditProps) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const patientId = useMemo(() => existingPatientId || v4(), []);

  const [confirmFn, setConfirmFn] = React.useState<{
    confirmFn: () => void;
    message: string;
  } | null>(null);

  return (
    <>
      <FormTitle
        useReducedTopPadding={true}
        hasTitleBottomMargin={false}
        titleText={
          mode === "create"
            ? t("pages.patientSearch.patientCreate.title")
            : `Edit patient ${initialState?.givenName} ${initialState?.familyNameAndAddress?.name}`
        }
      />
      {mode !== "create" && (
        <Banner
          sx={{ mb: 2 }}
          bannerType={BannerList.WARNING}
          title={t("pages.patientEditCreate.editPatientInformation", {
            givenName: initialState?.givenName || "",
            familyName: initialState?.familyNameAndAddress?.name || "",
            patientSearchRoute: routeFns.patientSearch(),
          })}
        />
      )}

      {confirmFn && (
        <ConfirmationModal
          message={confirmFn.message}
          confirmFn={confirmFn.confirmFn}
          closeFn={() => setConfirmFn(null)}
        />
      )}

      <Formik<any>
        validationSchema={patientCreateSchema}
        initialValues={{
          givenName: initialState?.givenName ?? null,
          familyNameAndAddress: {
            name: initialState?.familyNameAndAddress?.name ?? null,
            postalCode: initialState?.familyNameAndAddress?.postalCode ?? null,
            address: initialState?.familyNameAndAddress?.address ?? null,
            isConfirmed:
              initialState?.familyNameAndAddress?.isConfirmed ?? false,
          },
          isPatientDateOfBirth:
            typeof initialState?.isPatientDateOfBirth === "boolean"
              ? initialState?.isPatientDateOfBirth
              : null,
          dateOfBirth: initialState?.dateOfBirth ?? null,
        }}
        onSubmit={async (values: any) => {
          if (mode === "create") {
            const result = await api.patients.create(patientId, {
              type: "demographics",
              demographics: {
                name: {
                  given: values.givenName,
                  family: values.familyNameAndAddress.name,
                },
                address: {
                  address: values.familyNameAndAddress.address,
                  postalCode: values.familyNameAndAddress.postalCode,
                },
                dateOfBirth: values.dateOfBirth,
              },
            });

            if (result.status !== 200) {
              renderErrorToast({
                message: t("pages.patientEditCreate.createError"),
              });
            } else {
              renderSuccessToast({
                message: t("pages.patientEditCreate.createSuccess"),
              });
              navigate(routeFns.patientHome(patientId));
            }
          } else {
            setConfirmFn({
              message: t(`pages.patientEditCreate.editPatientConfirmation`, {
                givenName: initialState?.givenName,
                familyName: initialState?.familyNameAndAddress?.name,
              }),
              confirmFn: async () => {
                const result = await api.patients.update(patientId, {
                  expectedVersion: expectedVersion!,
                  type: "demographics",
                  demographics: {
                    name: {
                      given: values.givenName,
                      family: values.familyNameAndAddress.name,
                    },
                    address: {
                      address: values.familyNameAndAddress.address,
                      postalCode: values.familyNameAndAddress.postalCode,
                    },
                    dateOfBirth: values.dateOfBirth,
                  },
                });

                if (result.status !== 200) {
                  renderErrorToast({
                    message: t("pages.patientEditCreate.updateError"),
                  });
                } else {
                  renderSuccessToast({
                    message: t("pages.patientEditCreate.updateSuccess"),
                  });
                  mode === "editPatientDialog"
                    ? refetchAndRedirect && refetchAndRedirect()
                    : setShowUpdateSuccessDialog &&
                      setShowUpdateSuccessDialog(true);
                }
                setConfirmFn(null);
              },
            });
          }
        }}
      >
        {({
          values,
          setValues,
          errors,
          touched,
          setFieldTouched,
          handleBlur,
          setValues: formikSetValues,
          submitForm,
          isSubmitting,
        }) => {
          const fieldProps = {
            validationSchema: patientCreateSchema,
            context: {},
            values,
            errors,
            handleBlur,
            setFieldTouched,
            touched,
            setValues,
          };

          return (
            <>
              <Container>
                <Box width="100%" sx={{ mb: 1 }}>
                  <TextboxFormField
                    field={{
                      type: "textbox",
                      field: "givenName",
                      label: t("pages.patientSearch.patientCreate.givenName"),
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box width="100%" sx={{ mb: 2 }}>
                  <NameAddressFormField
                    field={{
                      type: "name-address",
                      field: "familyNameAndAddress",
                      disableEmail: true,
                      nameLabel: t(
                        "pages.patientSearch.patientCreate.familyNameAndAddress.nameLabel",
                      ),
                      addressLabel: t(
                        "pages.patientSearch.patientCreate.familyNameAndAddress.addressLabel",
                      ),
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
                <Box width="100%">
                  <BranchFormField
                    field={{
                      type: "branch",
                      field: "isPatientDateOfBirth",
                      label: t("pages.patientSearch.patientCreate.isDOBlabel"),
                      branches: [
                        {
                          label: t("common.yes"),
                          fieldValue: true,
                          fields: [
                            {
                              type: "date",
                              field: "dateOfBirth",
                              label: t(
                                "pages.patientSearch.patientCreate.dateOfBirth",
                              ),
                            },
                          ],
                          fieldsOwned: ["dateOfBirth"],
                        },
                        {
                          label: t("common.no"),
                          fieldValue: false,
                          fields: [],
                          fieldsOwned: [],
                        },
                      ],
                    }}
                    fieldProps={fieldProps}
                  />
                </Box>
              </Container>
              <FormFooterSection
                customFooterBackgroundColor={theme.palette.common.white}
                onSave={submitForm}
                saveLabel={
                  mode === "create"
                    ? t("buttonLabels.createPatient")
                    : t("buttonLabels.updatePatient")
                }
                saveVariant="contained"
                discardLabel={
                  mode === "create"
                    ? t("buttonLabels.backSearch")
                    : mode === "editPatientDialog"
                      ? t("buttonLabels.close")
                      : t("buttonLabels.backPatient")
                }
                onCancel={exitPatientCreate}
                disableDiscard={isSubmitting}
                disableSubmit={isSubmitting}
                minHeight
              />
            </>
          );
        }}
      </Formik>
    </>
  );
}
