import { object, string, number, bool, mixed } from "yup";
import dayjs from "dayjs";
import {
  MhaStatus,
  DischargeReason,
  DetainedStatuses,
} from "../types/patientState/PatientState";
import { formValidationErrorMessages } from "./shared";

const baseManualEventSchema = object({
  status: string().required(),
  startDateTime: string()
    .optional()
    .test(
      "testEpisodeCurrentStartDateTimeTimeNotInFuture",
      "Cannot enter a date in the future",
      function checkCurrentStartDateTimeTimeNotInFuture(
        startDateTime: string | null | undefined,
      ) {
        const { status, hasRenewals } = this.parent;

        const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
        if (requiredFor.includes(status) && hasRenewals) {
          return dayjs(startDateTime).isBefore(dayjs());
        }
        return true;
      },
    )
    .when("status", {
      is: (status: MhaStatus) => {
        const requiredFor = [
          MhaStatus.Section5_4,
          MhaStatus.Section5_2,
          MhaStatus.Section4,
        ];
        return requiredFor.includes(status);
      },
      then: (schema: any) =>
        schema.required("Please enter date and time of detention"),
    })
    .when("status", {
      is: (status: MhaStatus) => {
        const requiredFor = [MhaStatus.Section2];
        return requiredFor.includes(status);
      },
      then: (schema: any) => schema.required("Please enter date of detention"),
    })
    .when(["status", "hasRenewals"], {
      is: (status: MhaStatus, hasRenewals: boolean | undefined) => {
        const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
        return requiredFor.includes(status) && hasRenewals;
      },
      then: (schema: any) => schema.required("Please enter date of detention"),
    }),
  initialStartDateTime: string().when("status", {
    is: (status: MhaStatus) => {
      const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
      return requiredFor.includes(status);
    },
    then: (schema: any) => schema.required("Please enter date of detention"),
    otherwise: () => string().optional(),
  }),
  episodeOfCareDischargeReason: string().when("status", {
    is: (status: MhaStatus) => {
      const requiredFor = [MhaStatus.NotDetained];
      return requiredFor.includes(status);
    },
    then: (schema: any) =>
      schema
        .required("Please select a reason")
        .oneOf(Object.values(DischargeReason)),
    otherwise: () => string().optional(),
  }),
  episodeOfCareDischargeDateTime: string()
    .optional()
    .when("status", {
      is: (status: MhaStatus) => {
        const requiredFor = [MhaStatus.NotDetained];
        return requiredFor.includes(status);
      },
      then: (schema: any) =>
        schema.required("Please enter date and time of discharge "),
    }),
});

const validManualEventMhaStatuses = DetainedStatuses.concat(MhaStatus.Unknown);

const hospitalSchema = object()
  .optional()
  .shape({
    name: string()
      .trim()
      .max(255, formValidationErrorMessages.tooManyCharactersError)
      .optional(),
    address: string()
      .trim()
      .max(255, formValidationErrorMessages.tooManyCharactersError)
      .optional(),
    postalCode: string()
      .trim()
      .max(8, formValidationErrorMessages.postalCodeCharacterError)
      .optional(),
    odsCode: string().optional(),
  });

export const manualEventSchema = baseManualEventSchema.shape({
  status: mixed().oneOf(validManualEventMhaStatuses).required(),

  hospital: hospitalSchema,

  type: string().required().oneOf(["manualEvent"]),
  numberOfRenewals: number().when("status", {
    is: (status: MhaStatus) => {
      const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
      return requiredFor.includes(status);
    },
    then: (schema: any) => schema.required("Enter number of renewals for CTO"),
    otherwise: () => number().optional(),
  }),
});

export const formManualEventSchema = baseManualEventSchema.shape({
  hospital: hospitalSchema.concat(
    object().shape({
      isConfirmed: bool()
        .optional()
        .default(false)
        .when("address", {
          is: (a: string) => a,
          then: (s) => s.oneOf([true], "Address confirmation is required"),
          otherwise: (s) => s,
        }),
    }),
  ),
  hasRenewals: bool().when("status", {
    is: (status: MhaStatus) => {
      const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
      return requiredFor.includes(status);
    },
    then: (schema: any) =>
      schema.required("Please confirm if a renewal has taken place"),
    otherwise: () => string().optional(),
  }),
  numberOfRenewals: number().when(["status", "hasRenewals"], {
    is: (status: MhaStatus, hasRenewals: boolean | undefined) => {
      const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
      return requiredFor.includes(status) && hasRenewals;
    },
    then: (schema: any) => schema.required("Enter number of renewals for CTO"),
    otherwise: () => number().optional(),
  }),
  expiryDateTime: string()
    .optional()
    .test(
      "testCtoOrSection3CurrentExpiryDateTimeTimeNotInPast",
      "Cannot enter a date in the past",
      function checkCtoOrSection3CurrentExpiryDateTimeTimeNotInPast(
        expiryDateTime: string | null | undefined,
      ) {
        const { status, hasRenewals } = this.parent;

        const requiredFor = [MhaStatus.Section17A, MhaStatus.Section3];
        if (requiredFor.includes(status) && hasRenewals) {
          return dayjs(expiryDateTime).isAfter(dayjs());
        }
        return true;
      },
    ),
});
